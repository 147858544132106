<script setup lang="ts">
import BestSellers from "~/components/Homepage/BestSellers.vue";
import { useOnboardingStore } from "~/stores/onbording.store";

definePageMeta({
  layout: "default",
});

const onboardingStore = useOnboardingStore();
const menusStore = useMenusStore();

const { abandonedCartId } = storeToRefs(onboardingStore);

onMounted(async () => {
  await menusStore.fetchMenuItems();
});

const menuItems = computed(() => {
  return menusStore.menuItems.sort(
    (a, b) => b.homepage_sorting_weight - a.homepage_sorting_weight
  );
});

const openFaqId = ref(null);
const faqs = ref([
  {
    id: 1,
    question: "What makes Nurture Life different?",
    answer:
      "Feeding your kid is hard. You have to think about whether they’ll like it, how long it’ll take you to make it, is it nutritious, do you have the ingredients, have you already fed them this same meal 14 times this month. And the list goes ON. Nurture Life is the only baby and kids meal delivery service that helps you tackle every single one of your mealtime needs.\n" +
      "\n" +
      "We prioritize balanced nutrition (including veggies in every meal!) while capturing the flavors that babies, toddlers and kids actually want to eat. Our flexible ordering system makes it easy to choose meals that fit your family’s ever-changing schedule—and so does the fact that every meal can be ready to eat in just 1 minute.\n" +
      "\n" +
      "We’re here for you as your baby learns to self-feed. We’re here for your toddler’s journey into more variety and bigger bites. We’re here for your kid’s ongoing healthy eating habits. And we’re here for every meal of the day. Breakfast, lunch, dinner and snack time, we’ve got you covered.\n" +
      "\n" +
      "Flexible, flavor-focused and absolutely uncompromising on nutrition. That’s Nurture Life.\n" +
      "\n",
  },
  {
    id: 2,
    question: "Who is Nurture Life for?",
    answer:
      "<p>Feeding your kid is hard. You have to think about whether they’ll like it, how long it’ll take you to make it, is it nutritious, do you have the ingredients, have you already fed them this same meal 14 times this month. And the list goes ON. Nurture Life is the only baby and kids meal delivery service that helps you tackle every single one of your mealtime needs.<br> <br>\n" +
      "We prioritize balanced nutrition (including veggies in every meal!) while capturing the flavors that babies, toddlers and kids actually want to eat. Our flexible ordering system makes it easy to choose meals that fit your family’s ever-changing schedule—and so does the fact that every meal can be ready to eat in just 1 minute.<br> <br>\n" +
      "We’re here for you as your baby learns to self-feed. We’re here for your toddler’s journey into more variety and bigger bites. We’re here for your kid’s ongoing healthy eating habits. And we’re here for every meal of the day. Breakfast, lunch, dinner and snack time, we’ve got you covered.<br> <br>\n" +
      "Flexible, flavor-focused and absolutely uncompromising on nutrition. That’s Nurture Life.</p>",
  },
  {
    id: 3,
    question: "How does Nurture Life work?",
    answer:
      "<p>Nurture Life is for busy parents who want more time in their full (and ever fuller!) daily lives—without having to make sacrifices about what they’re feeding their kids. We take the stress out of meal preparation by providing healthy, ready-to-eat meals that babies, toddlers and kids actually want to eat.<br> <br>\n" +
      "Our delicious baby and kids meals are freshly made for all the little ones in your home. From babies who are just learning to grab at the high chair tray to active toddlers and kids who need a healthy, balanced meal to fuel them throughout the day, Nurture Life is a palate pleaser. Even picky eaters can find a favorite on our kids meal delivery menu!<br> <br>\n" +
      "We deliver meals your family will love, and you get time back in your busy life—exactly what every parent needs. </p>",
  },
  {
    id: 5,
    question: "What are Nurture Life’s nutritional standards?",
    answer:
      "<p>All of our meals are designed by registered dietitians, ensuring we always meet exceptionally high nutrition standards and follow our meal philosophy:<br>\n" +
      "- Delicious AND nutritious<br>\n" +
      "- Veggies in every meal<br>\n" +
      "- Good source of protein<br>\n" +
      "- Real, organic ingredients<br>\n" +
      "- Fresh, never frozen<br> <br>\n" +
      "You can feel good knowing we also have strict standards for what DOESN’T go into our meals:<br>\n" +
      "- No trans fat<br>\n" +
      "- No high fructose corn syrup<br>\n" +
      "- No artificial colors or flavors<br>\n" +
      "- No nitrates<br> <br>Plus, we keep sugar and salt low so your kid can enjoy the natural deliciousness of our real, clean ingredients.With every meal we put on the Nurture Life menu, we’re raising the standard for what goes on your kids’ plates!<br></p>",
  },
  {
    id: 6,
    question: "Is Nurture Life picky eater-friendly?",
    answer:
      "<p>Yes! We know that picky eating is an obstacle to healthy eating, so we’ve made picky eater-friendly meals that still maintain our high nutritional standards.<br> <br>\n" +
      'To find picky eater meals, use the filters on <u><a href="https://www.nurturelife.com/menus/">our menu</a></u> and look for the Picky Eater Fav banner. You’ll find a variety of deliciously balanced kid favorites, including Mac &amp; Cheese with Hidden Butternut Squash and Chicken Meatballs with Spiral Pasta &amp; Veggies. Many of these options smoothly blend veggies right into the sauce so your kid won’t even notice the veggies are there!<br> <br>\n' +
      "Nurture Life is also here to help your picky eater become not-so picky! Our menu features meals that can help expose picky eaters to new colors, textures and tastes. We recommend gradually incorporating meals from outside of our Picky Eater Favorites to familiarize your child with a wider variety of flavors. Remember that it can take 9–15 tries for kids to accept a new food, so stick with it! You’re doing the right thing, and patience does pay off.<br> <br>\n" +
      'Whether you order our kids meal delivery or not, we also offer a ton of picky eater resources for you to draw from. When you feel like you just can’t handle another “no” at the table, check out our helpful resources from experts on <u><a href="/blog/category/picky-eaters/">overcoming picky eating together</a></u> or contact us at support@nurturelife.com for help from one of our registered dietitians!</p>',
  },
  {
    id: 7,
    question: "Is Nurture Life allergy-friendly?",
    answer:
      "<p>All our meals are made in a facility free of peanuts and tree nuts (except coconut), so we’re a great option for families with nut allergies! We also offer many Finger Foods, Kids Meals and Snacks that are free from common food allergens like egg, milk, wheat, fish, soy and sesame.<br> <br>\n" +
      'Our kitchen does process coconut, milk, eggs, fish (salmon), soy, wheat and sesame. We recommend that you closely review our full ingredient lists and the “contains” statement associated with each of our meals if you have a family member with a food allergy. From <u><a href="/menus/">our menu</a></u>, these can be found by clicking on any meal for further details. All our meal packaging also includes full ingredient lists.<br> <br>\n' +
      "While we always follow best practices for allergen controls, if your child has celiac disease, Nurture Life might not be the right option for you. Please contact us if you have any questions about your specific needs at support@nurturelife.com or 877.988.8851.</p>",
  },
  {
    id: 8,
    question: "Does Nurture Life’s menu change?",
    answer:
      "<p>Every week, we offer a core set of customer favorite meals that never change. At the same time, we keep things fresh and interesting by consistently introducing new flavors for your kids to try! We launch exciting new meals every 3 months and always have at least 25 Kids Meals, 12 Finger Foods and a selection of deliciously cookie-inspired Snacks on the menu.<br> <br>\n" +
      'This mix of familiar and new gives you a wide variety of nutritious meals to choose from, each containing a diverse range of organic veggies and antibiotic- and hormone-free proteins. You’ll be able to select the right meals for your baby, toddler or kid at every stage of their eating journey—whether your little one is currently on an adventurous streak or is stuck in that “mac and cheese only” phase. Our <u><a href="/menus/">healthy menu</a></u> has something for everyone.<br> <br>\n' +
      "You also have a say in what comes next on the Nurture Life menu. We’re honored to be invited to your family’s table, and we take your feedback seriously as we consider changes to our meals. So please share your feedback with our team at support@nurturelife.com and remember to check back frequently to see what’s fresh!</p>",
  },
  {
    id: 9,
    question: "Can I change meals in my subscription?",
    answer:
      "<p>Yes! You define the quantity of meals in your order each week, and we fill your order with recommended meals based on your selection, but you can always change our meal recommendations. You can also customize your account to get the best recommendations! We make it easy for you to set your account and forget it, or if you like being more hands on, you can always head into your account and switch up your order or skip a week.<br> <br>\n" +
      "Please note that in order to fulfill our promise of fresh, not frozen, we do have a cutoff deadline for each order. If you change your family’s preferences after an order cutoff has already passed, that order will be filled based on your previous preferences. All future orders will be filled based on your new preferences.<br> <br>\n" +
      'Not sure when the cutoff is? You can find this information in the <u><a href="http://account.nurturelife.com/orders">orders</a></u> section of your account.</p>',
  },
  {
    id: 10,
    question: "How much does Nurture Life cost?",
    answer:
      "<p>Nurture Life is super flexible to accommodate whatever your family needs. Our meals are priced individually, with Finger Foods at $6.89, Kids Meals starting at $7.99 and Snacks at $7.99. Simply choose a plan based on the number of meals you’re interested in, and your total cost is based on the mix of products you order. We start at a 6 meal plan, with free shipping on 9+ meal plans and discounts of up to $15 off your entire order:<br> <br>\n" +
      "- 6 Meal Plan<br>\n" +
      "- 9 Meal Plan = free shipping<br>\n" +
      "- 12 Meal Plan = $10 off your entire order + free shipping<br>\n" +
      "- 15 Meal Plan = $15 off your entire order + free shipping</p>",
  },
  {
    id: 11,
    question: "How do I get started?",
    answer:
      '<p>It’s simple! Just choose the meals and snacks you want to try from <u><a href="/menus/">our menu</a></u> and check out.<br> <br>\n' +
      "Once you place your order, it’s time for us to get cooking! Meals typically arrive within a week to a week and a half depending on the day you signed up and your delivery location. You will be able to view your first and future scheduled delivery days in your account. Once your meals are on their way, we will send an email with the tracking information so your family can excitedly anticipate your first kids meal delivery box!<br> <br>\n" +
      'Get started building your first <u><a href="/menus/">order</a></u> today.</p>',
  },
]);

const faqClicked = (faq) => {
  if (openFaqId.value === faq.id) {
    openFaqId.value = null;
  } else {
    openFaqId.value = faq.id;
  }
};
</script>
<template>
  <div
    class="md:tw-min-h-[667px] md:tw-flex tw-items-center tw-justify-center tw-relative tw-overflow-hidden tw-bg-gradient-to-r tw-from-[#dde6f5] tw-to-[#d9e2ee] md:tw-bg-transparent -tw-mt-5 md:tw-mt-0"
  >
    <div class="tw-absolute tw-inset-0 tw-hidden md:tw-block">
      <img
        src="https://content.nurturelife.com/wp-content/uploads/2024/06/home-hero-2-scaled-1.jpg"
        aria-hidden="true"
        class="tw-w-full tw-h-full tw-object-cover"
        alt="Nurture Life Background Image Desktop"
      />
    </div>
    <div class="md:tw-hidden">
      <img
        src="https://content.nurturelife.com/wp-content/uploads/2024/06/home-hero-mobile-1.jpg"
        aria-hidden="true"
        class="tw-w-full"
        alt="Nurture Life Background Image Mobile"
      />
    </div>
    <div class="tw-container">
      <div
        class="md:tw-max-w-[700px] md:tw-min-h-[388px] tw-mx-auto tw-flex tw-items-center tw-justify-center tw-relative tw-z-2 tw-text-[16px] md:tw-text-[30px] tw-leading-[1.23] tw-text-nl-green-100 md:tw-text-nl-yellow-100 tw-text-center tw-pb-8 md:tw-py-5 md:tw-px-[60px]"
      >
        <div class="tw-hidden md:tw-block tw-absolute tw-inset-0 tw-z-1">
          <svg
            width="665"
            height="389"
            viewBox="0 0 665 389"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            class="tw-w-full tw-h-full"
          >
            <path
              d="M651.949 96.9315C622.621 1.81776 567.128 -14.3902 426.457 13.2111C324.372 33.2412 61.3824 -64 17.997 83.3656C-16.9529 202.079 -0.999817 361.251 89.0002 378.251C171.184 393.775 223.224 362.305 342.033 378.251C460.842 394.197 556.861 394.67 604 353C651.139 311.33 683.491 199.226 651.949 96.9315Z"
              fill="#007D4D"
            />
          </svg>
        </div>
        <div class="tw-relative tw-z-2">
          <h1 class="tw-text-h1-sm md:tw-text-h1">
            GROWING UP.<br />
            EATING WELL.
          </h1>
          <div class="tw-font-bold tw-mb-3 md:tw-mb-5 max-md:-tw-mx-3">
            Delicious, nutritious meals and snacks for <br> your kids.
            Extra time back in your day.
          </div>
          <a
            href="/onboarding/signup/"
            class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-bg-nl-blue-200 md:hover:tw-bg-nl-blue-600 md:focus:tw-bg-nl-blue-600 md:tw-text-nl-green-100 tw-mb-2 md:tw-font-bold"
          >
            SEE MENU & PRICING
          </a>
          <HomepageMinPrice />
        </div>
      </div>
    </div>
    <div
      class="md:tw-hidden tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-[30px]"
      style="
        background: url('/assets/images/home/hero-curve-sm.png')
          no-repeat 50% 0 / cover;
      "
    ></div>
    <div
      class="tw-hidden md:tw-block tw-absolute tw-bottom-0 -tw-left-[30px] -tw-right-[30px] tw-h-[18px]"
      style="
        background: url('/assets/images/home/hero-curve.svg')
          no-repeat 50% 0 / cover;
      "
    ></div>
  </div>

  <div class="tw-pt-5 md:tw-pt-8 tw-pb-[14px] tw-overflow-hidden">
    <div class="tw-container">
      <div
        class="min-[376px]:tw-max-w-[317px] md:tw-max-w-[430px] lg:tw-max-w-[857px] -tw-mx-[3px] min-[376px]:tw-mx-auto"
      >
        <h2
          class="tw-text-center tw-text-h2-sm md:tw-text-h2 !tw-leading-[0.875] tw-text-nl-green-100 tw-uppercase tw-mb-1 md:tw-mb-6"
        >
          WHAT’S ON THE MENU?
        </h2>
        <div class="tw-grid lg:tw-grid-cols-2 tw-gap-7">
          <div
            v-for="(menuItem, i) in menuItems"
            :key="menuItem.id"
            class="tw-flex tw-flex-wrap tw-pb-[56px] tw-relative md:tw-pb-0"
            :class="{
              'tw-flex-row-reverse lg:tw-flex-row': i % 2 === 1,
            }"
          >
            <div
              class="tw-w-[154px] md:tw-w-[222px] tw-h-[170px] md:tw-h-[248px] tw-overflow-hidden tw-rounded-[19px] md:tw-rounded-[30px] tw-p-[7px] md:tw-p-[10px]"
              :style="{
                'background-color': menuItem.color,
              }"
            >
              <img
                :src="menuItem.image"
                class="tw-w-full tw-h-full tw-object-cover tw-rounded-[12px] md:tw-rounded-[20px]"
                :alt="menuItem.name + ' Thumbnail'"
              />
            </div>
            <div
              class="tw-text-center tw-text-[14px] md:tw-text-[17px] tw-leadding-[1.142] md:tw-leading-[1.17] tw-text-nl-green-100 tw-font-medium"
              :class="{
                'tw-flex-1 tw-pt-2 tw-pr-3 lg:tw-pr-0 lg:tw-pl-3': i % 2 === 1,
                'tw-flex-1 tw-pt-1 md:tw-pt-2 tw-pl-3': i % 2 === 0,
              }"
            >
              <strong
                class="tw-inline-block tw-font-black tw-text-[21px] md:tw-text-[25px] tw-leading-[1.208] tw-text-nl-green-100 tw-uppercase tw-pb-1 tw-relative tw-mb-[7px] md:tw-mb-[14px] after:tw-content-[''] after:tw-absolute after:tw-left-0 after:tw-bottom-0 after:tw-right-0 after:tw-h-2 after:tw-rounded-[8px] after:tw-bg-[var(--subtitle-border-color)]"
                :style="{
                  '--subtitle-border-color': menuItem.color,
                }"
              >
                {{ menuItem.name }}
              </strong>
              <p
                class="tw-max-w-[149px] md:tw-max-w-[179px] tw-mx-auto md:tw-mb-3"
              >
                {{ menuItem.homepage_description }}
              </p>
              <div
                class="tw-absolute md:tw-static tw-bottom-0 tw-left-0 tw-right-0"
              >
                <a
                  :href="'/menus/?navItem=' + menuItem.slug"
                  class="tw-font-bold tw-text-[16px] md:tw-text-[20px] tw-leading-none tw-text-white md:tw-text-nl-green-100 md:hover:tw-text-nl-green-400 md:focus:tw-text-nl-green-400 tw-uppercase md:tw-underline tw-underline-offset-2 tw-bg-nl-green-100 md:tw-bg-transparent hover:tw-bg-nl-green-400 hover:md:tw-bg-transparent tw-w-full md:tw-w-auto tw-block md:tw-inline-block tw-rounded-[7px] md:tw-rounded-[0] tw-p-3 md:tw-p-0 tw-transition-all"
                >
                  SEE {{ menuItem.name }}
                </a>
              </div>
            </div>
          </div>

          <div
            v-if="menusStore.menuItems.length % 2 === 1"
            class="tw-hidden md:tw-flex tw-flex-wrap tw-items-center tw-pb-[56px] tw-relative md:tw-pb-0 tw-flex-row-reverse lg:tw-flex-row"
          >
            <div
              class="tw-w-[154px] md:tw-w-[222px] tw-h-[170px] md:tw-h-[248px] tw-overflow-hidden tw-rounded-[20px] md:tw-rounded-[32px] tw-border-[7px] md:tw-border-[10px]"
              style="border-color: rgb(94, 201, 241)"
            >
              <img
                src="https://content.nurturelife.com/wp-content/uploads/2024/06/full-menu.jpg"
                class="tw-w-full tw-h-full tw-object-cover tw-rounded-[12px] md:tw-rounded-[20px]"
                alt="Full menu thumbnail"
              />
            </div>
            <div
              class="tw-flex-1 tw-pt-2 tw-pr-3 lg:tw-pr-0 lg:tw-pl-3 tw-text-center tw-text-[14px] md:tw-text-[17px] tw-leadding-[1.142] md:tw-leading-[1.17] tw-text-nl-green-100"
            >
              <strong
                class="tw-text-[32px] tw-leading-[1.0625] tw-text-nl-green-100 tw-font-black tw-block tw-uppercase tw-mb-3"
              >
                EXPLORE THE FULL MENU!
              </strong>
              <div
                class="tw-absolute md:tw-static tw-bottom-0 tw-left-0 tw-right-0"
              >
                <a
                  href="/menus/"
                  class="tw-font-bold tw-text-[16px] md:tw-text-[20px] tw-leading-none tw-text-white md:tw-text-nl-green-100 md:hover:tw-text-nl-green-400 md:focus:tw-text-nl-green-400 tw-uppercase md:tw-underline tw-underline-offset-2 tw-bg-nl-green-100 md:tw-bg-transparent hover:tw-bg-nl-green-400 hover:md:tw-bg-transparent tw-w-full md:tw-w-auto tw-block md:tw-inline-block tw-rounded-[7px] md:tw-rounded-[0] tw-p-3 md:tw-p-0 tw-transition-all"
                  >See Menu</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-hidden md:tw-flex tw-flex-wrap tw-items-center tw-pb-[56px] tw-relative md:tw-pb-0 tw-justify-center tw-mt-[35px]"
        >
          <a
            href="/onboarding/signup/"
            class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
          >
            SEE MENU & PRICING
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="tw-relative tw-overflow-hidden">
    <div
      class="tw-hidden md:tw-block tw-absolute tw-top-0 tw-left-0 -tw-right-[50px] tw-h-[62px] min-[1920px]:-tw-left-[10%] min-[1920px]:-tw-right-[10%] tw-h-[62px]"
      style="
        background: url('/assets/images/home/bg-curve-t.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
    <div class="md:tw-bg-nl-blue-300 tw-pt-4 md:tw-pt-12 tw-pb-[68px]">
      <div class="tw-container">
        <div class="md:tw-hidden tw-text-center lg:tw-text-left">
          <h2
            class="tw-text-h2-sm md:tw-text-h2 tw-text-nl-green-100 tw-uppercase tw-mb-2"
          >
            How it works
          </h2>
        </div>
        <div
          class="md:tw-flex tw-flex-wrap tw-items-center tw-max-w-[810px] tw-mx-auto"
        >
          <div
            class="tw-w-[334px] lg:tw-w-[436px] tw-h-[250px] lg:tw-h-[324px] tw-mx-auto lg:tw-mx-0 masked-img tw-relative"
          >
            <img
              src="https://content.nurturelife.com/wp-content/uploads/2024/06/how-it-works.jpg"
              class="mask"
              alt="Image"
            />
          </div>
          <div
            class="tw-text-center lg:tw-text-left tw-pt-[10px] md:tw-pt-0 md:tw-flex-1 md:tw-pl-11"
          >
            <div class="tw-hidden md:tw-block">
              <h2
                class="tw-text-h2-sm md:tw-text-h2 tw-text-nl-green-100 tw-uppercase tw-mb-2"
              >
                HOW IT WORKS
              </h2>
            </div>
            <ol
              class="tw-text-nl-green-100 tw-text-[18px] tw-leading-[1.167] tw-font-bold -tw-my-[7px]"
            >
              <li class="tw-py-[7px]">
                <span class="tw-font-black">1</span>. Choose meals & snacks
              </li>
              <li class="tw-py-[7px]">
                <span class="tw-font-black">2</span>. We cook & deliver them
                fresh
              </li>
              <li class="tw-py-[7px]">
                <span class="tw-font-black">3</span>. Serve in 1 minute or less
              </li>
            </ol>
            <div class="tw-mt-4">
              <a
                href="/onboarding/signup/"
                class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
                >SEE MENU & PRICING</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-hidden md:tw-block tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-[47px] min-[1920px]:-tw-left-[10%] min-[1920px]:-tw-right-[10%]"
      style="
        background: url('/assets/images/home/bg-curve-b.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
  </div>

  <div
    class="tw-pt-2 tw-pb-[100px] md:tw-py-2 tw-text-center md:tw-overflow-hidden tw-bg-nl-blue-300 md:tw-bg-transparent tw-relative"
  >
    <div
      class="tw-block md:tw-hidden tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-h-[20px] min-[540px]:tw-h-[40px]"
      style="
        background: url('/assets/images/home/bg-curve-t-1.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
    <div class="tw-container">
      <div class="tw-max-w-[920px] tw-mx-auto">
        <div class="tw-mb-[30px]">
          <div class="tw-relative">
            <div class="-tw-mx-[34px] -tw-mt-[50px] tw-mb-[26px] md:tw-hidden">
              <img
                src="https://content.nurturelife.com/wp-content/uploads/2024/06/kid-1.webp"
                class="tw-w-full"
                alt="Kid Image"
              />
            </div>
            <div
              class="tw-absolute -tw-right-[20px] tw-top-[70px] md:tw-static tw-max-w-[134px] md:tw-max-w-none"
            >
              <h2
                class="tw-text-h2-sm md:tw-text-h2 tw-uppercase tw-text-nl-green-100 tw-mb-[14px]"
              >
                TALK AROUND THE KIDS’ TABLE
              </h2>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-mb-[14px] md:tw-mb-[9px]">
            <div
              class="tw-w-[226px] md:tw-w-[280px] tw-h-[41px] md:tw-h-[50px] tw-relative"
            >
              <div
                class="tw-absolute tw-inset-y-0 tw-left-0 -tw-right-[3px] tw-bg-[length:46px_41px] md:tw-bg-[length:57px_50px] tw-z-10"
                style="
                  background-image: url('https://content.nurturelife.com/wp-content/uploads/2024/06/star-partial.svg');
                "
              ></div>
              <div
                class="tw-absolute tw-h-full tw-bg-[length:46px_41px] md:tw-bg-[length:57px_50px] tw-z-30"
                style="
                  width: 95.55%;
                  background-image: url('https://content.nurturelife.com/wp-content/uploads/2024/06/star-full.svg');
                "
              ></div>
            </div>
          </div>
          <span
            class="tw-block tw-w-full tw-tex-center tw-text-nl-green-100 tw-text-[16px] md:tw-text-[20px] tw-font-normal"
          >
            4.9/5 (1748 Reviews)
          </span>
        </div>
        <HomepageTestimonials />
        <div class="tw-pt-[18px] sm:tw-pt-8 tw-text-center">
          <a
            href="/onboarding/signup/"
            class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
            >See Menu & PRICING</a
          >
        </div>
      </div>
    </div>

    <div
      class="tw-block md:tw-hidden tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-[14px]"
      style="
        background: url('/assets/images/home/bg-curve-b-1.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
  </div>

  <div
    class="tw-relative tw-overflow-hidden tw-text-center -tw-mt-[82px] md:tw-mt-0"
  >
    <div
      class="tw-hidden md:tw-block tw-absolute tw-top-0 tw-left-0 -tw-right-[50px] tw-h-[62px] min-[1920px]:-tw-left-[10%] min-[1920px]:-tw-right-[10%]"
      style="
        background: url('/assets/images/home/bg-curve-t-1.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
    <div
      class="md:tw-bg-nl-blue-300 md:tw-pt-[70px] tw-pb-[24px] md:tw-pb-[34px]"
    >
      <div class="md:tw-hidden tw-mb-[10px]">
        <img
          src="https://content.nurturelife.com/wp-content/uploads/2024/06/veg-img-3.webp"
          class="tw-w-full"
          alt="Veg Image 3"
        />
      </div>
      <div class="tw-container">
        <div
          class="md:tw-max-w-[1150px] -tw-mx-[10px] md:tw-mx-auto tw-relative"
        >
          <div
            class="tw-hidden md:tw-block tw-absolute tw-left-0 -tw-top-[26px] tw-w-1/4 xl:tw-w-auto"
          >
            <img
              src="https://content.nurturelife.com/wp-content/uploads/2024/06/veg-img-1.webp') "
              width="306"
              alt="Veg Image 1"
            />
          </div>
          <div
            class="tw-max-w-[301px] md:tw-max-w-[465px] tw-mx-auto tw-w-full md:tw-w-1/2 xl:tw-w-auto md:tw-px-5 lg:tw-px-0 tw-font-normal"
          >
            <h2
              class="tw-text-h2-sm sm:tw-text-h2 !tw-leading-[1.125] tw-uppercase tw-text-nl-green-100 tw-mb-[14px] md:tw-mb-[10px]"
            >
              WHERE THE VEGGIES AT?
            </h2>
            <div
              class="tw-font-boyrun tw-text-nl-green-400 tw-text-[21px] md:tw-text-[26px] tw-leading-[1.15] tw-mb-[5px] md:tw-mb-[10px]"
            >
              <p>
                Less than 10% of children in the U.S. consume enough vegetables
                per day.
              </p>
            </div>
            <p class="tw-mb-[18px] md:tw-mb-7 tw-font-medium">
              But with our meals and snacks, your kid can get the veggies they
              need! We incorporate veggies every chance we get, blending them in
              sauces, mixing them into our pockets, and letting them shine by
              themselves as a side.
            </p>
            <a
              href="/onboarding/signup/"
              class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
              >See Menu & PRICING</a
            >
          </div>
          <div
            class="tw-hidden md:tw-block tw-absolute tw-right-0 tw-bottom-[30px] tw-w-1/4 xl:tw-w-auto"
          >
            <img
              src="https://content.nurturelife.com/wp-content/uploads/2024/06/veg-img-2.webp') "
              width="324"
              alt="Veg Image 2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="tw-bg-nl-green-400 tw-py-[38px] md:tw-py-6 tw-relative tw-overflow-hidden"
  >
    <div
      class="tw-block md:tw-hidden tw-absolute -tw-top-px tw-left-0 tw-right-0 tw-h-[25px] min-[540px]:tw-h-[40px] -tw-mx-[10px]"
      style="
        background: url('/assets/images/home/bg-curve-t-1.svg')
          no-repeat 0 0 / cover;
      "
    ></div>
    <div class="tw-container tw-flex tw-justify-center">
      <ul
        class="tw-grid tw-grid-cols-2 md:tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-4 tw-gap-y-[32px] md:tw-gap-[34px]"
      >
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/forbes-white.svg"
            width="140"
            alt="Forbes logo"
          />
        </li>
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/wired-white.svg"
            width="140"
            alt="Wired logo"
          />
        </li>
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/Verywell.svg"
            width="140"
            alt="Verywell logo"
          />
        </li>
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/VOGUE.svg"
            width="140"
            alt="Vogue logo"
          />
        </li>
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/parents.svg"
            width="140"
            alt="Parents logo"
          />
        </li>
        <li class="tw-text-center md:tw-text-left">
          <img
            src="https://content.nurturelife.com/wp-content/uploads/2024/06/Healthline.svg"
            width="140"
            alt="Healthline logo"
          />
        </li>
      </ul>
    </div>
    <div
      class="tw-block md:tw-hidden tw-absolute -tw-bottom-px tw-left-0 tw-right-0 max-[400px]:tw-h-[15px] max-[580px]:tw-h-[20px] tw-h-[24px] -tw-mx-[10px]"
      style="
        background: url('/assets/images/home/bg-curve-b-2.svg')
          no-repeat 100% 0 / cover;
      "
    ></div>
  </div>

  <section class="tw-pt-[13px] md:tw-pt-9 tw-pb-5 tw-relative">
    <div class="tw-container">
      <div class="tw-mx-auto tw-max-w-[1038px]">
        <h2
          class="tw-text-center tw-text-h2-sm md:tw-text-h2 tw-text-nl-green-100 tw-uppercase tw-mb-5 md:tw-mb-6"
        >
          Best Sellers
        </h2>

        <BestSellers />

        <!--        <a href="/menus/" class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]">See Menu & PRICING</a>-->
      </div>
    </div>
  </section>

  <section class="tw-pt-1 tw-pb-5 md:tw-pb-6">
    <div class="tw-container">
      <div class="tw-mx-auto tw-max-w-[653px]">
        <div class="tw-flex tw-flex-col md:tw-block">
          <h2
            class="tw-text-center tw-text-h2-sm md:tw-text-h2 tw-text-nl-green-100 tw-mb-1.5 tw-normal-case"
          >
            FAQs
          </h2>
          <div
            class="md:tw-hidden tw-text-center tw-text-[16px] tw-leading-[1.125] md:tw-text-[17px] md:tw-leading-[1.1765] tw-text-nl-green-100 tw-font-medium"
          >
            <p class="tw-mb-[12px]">
              Don’t see your question answered here? Visit
              <br class="md:hidden" />
              our
              <a
                class="tw-underline hover:tw-no-underline"
                target="_blank"
                href="/faq"
                >FAQs page</a
              >
              for more!
            </p>
          </div>
          <div class="tw-mb-[22px] md:tw-mb-[30px]">
            <ul
              class="tw-text-[16px] tw-leading-[1.125] md:tw-text-[17px] md:tw-leading-[1.1765] tw-font-medium tw-text-nl-green-100 -tw-my-1"
            >
              <li
                v-for="(faq, index) in faqs"
                :id="faq.question"
                class="tw-border tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-nl-green-300o15"
              >
                <a
                  href="#"
                  @click.prevent="() => faqClicked(faq)"
                  class="tw-font-bold tw-pb-3.5 md:tw-pb-[14px] tw-pt-[11px] tw-pr-4 tw-block tw-relative tw-text-nl-green-100 hover:tw-text-nl-green-300 tw-transition-colors md:!tw-leading-[0.9411]"
                >
                  {{ faq.question }}
                  <span
                    :class="{ 'tw-rotate-90': openFaqId === faq.id }"
                    class="icon-main-chevron-bold-right tw-absolute tw-right-1 tw-text-[14px] tw-top-1/2 -tw-mt-2 tw-transition-all"
                  ></span>
                </a>
                <div
                  class="tw-pr-1.5 md:tw-pr-12 -tw-mt-px tw-pb-[13px]"
                  :class="{
                    'tw-hidden': openFaqId !== faq.id,
                    'tw-block': openFaqId === faq.id,
                  }"
                >
                  <div class="tw-mb-[13px]" v-html="faq.answer"></div>
                  <a
                    href="/onboarding/signup/"
                    class="btn-green !tw-leading-[0.6] tw-min-h-[40px] tw-inline-flex tw-items-center"
                    v-if="index !== faqs.length - 1"
                  >
                    SEE MENU & PRICING
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="max-md:tw-hidden tw-text-center tw-text-[16px] tw-leading-[1.125] md:tw-text-[17px] md:tw-leading-[1.1765] tw-text-nl-green-100 tw-font-medium"
          >
            <p class="tw-mb-[12px]">
              Don’t see your question answered here? Visit our
              <a
                class="tw-underline hover:tw-no-underline"
                target="_blank"
                href="/faq"
                >FAQs page</a
              >
              for more!
            </p>
          </div>
          <div class="sm:tw-pt-3 tw-text-center tw-order-3">
            <a
              href="/onboarding/signup/"
              class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
            >
              SEE MENU & PRICING
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    class="md:tw-hidden tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-nl-blue-500 tw-py-[10px] tw-shadow-nl-4 tw-flex tw-justify-center tw-items-center tw-z-50"
  >
    <div class="tw-flex tw-flex-wrap tw-gap-[18px] tw-text-center">
      <div class="tw-text-center">
        <div class="tw-mb-[6px]">
          <div
            class="tw-w-[100px] tw-h-5 tw-bg-[length:20px_auto] tw-bg-repeat-x"
            style="
              background-image: url('https://content.nurturelife.com/wp-content/uploads/2024/06/star-partial.svg');
            "
          >
            <div
              class="tw-h-full tw-bg-[length:20px_auto] tw-bg-repeat-x"
              style="
                width: 94%;
                background-image: url('https://content.nurturelife.com/wp-content/uploads/2024/06/star-full.svg');
              "
            ></div>
          </div>
        </div>
        <span
          class="tw-block tw-text-[11px] tw-leading-none tw-text-nl-green-100"
        >
          4.9/5 (1748 Reviews)</span
        >
      </div>
      <div>
        <a
          href="/onboarding/signup/"
          class="btn tw-min-h-[41px] md:tw-min-h-[45px] md:tw-pt-[11px]"
        >
          SEE MENU & PRICING
        </a>
      </div>
    </div>
  </div>
</template>
