<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import {getLeastExpensiveProduct} from '@/services/api/products';
import {restoreAbandonedCartId} from '@/services/storage';
import {findGetParameter} from "~/utils/helpers";
import {DEFAULT_PRODUCT_CATEGORY_VARIANT_1} from "~/constants/experiment-variants";

interface Product {
  discount: number;
  discount_type: string;
  price: {
    data: {
      display_price: number;
      original_display_price: number;
      display_price_in_cents: number;
      original_display_price_in_cents: number;
    }
  }
}

const product = ref<Product | null>(null);
const busy = ref(true);
const cartStore = useCartStore();
const experimentStore = useExperimentStore();
const {promoCode, referralCode} = storeToRefs(cartStore)
const {isExperimentsLoaded} = storeToRefs(experimentStore)

const format = (money: number) => {
  return formatMoney(money);
};

const getProduct = async () => {
  if (!isExperimentsLoaded.value) {
    return;
  }
  busy.value = true;
  try {
    let tempPromo = findGetParameter("pc");
    product.value = await getLeastExpensiveProduct(
        restoreAbandonedCartId(),
        tempPromo ? tempPromo : (promoCode ? promoCode.code : null)
    );
  } catch (exception: any) {
    const is403 = exception.response && exception.response.status === 403;
    if (!is403) {
      throw exception;
    }
  } finally {
    busy.value = false;
  }
};

watch(promoCode, () => {
  getProduct()
})

watch(referralCode, () => {
  getProduct()
})

watch(isExperimentsLoaded, () => {
  getProduct()
})

const showPrice = computed(() => {
  return price.value >= 0 && isExperimentsLoaded.value;
});

const pricePrefixText = computed(() => {
  return experimentStore.hasVariant(DEFAULT_PRODUCT_CATEGORY_VARIANT_1) ? 'Starting at' : 'From';
});

const price = computed(() => {
  return product.value?.price?.data?.display_price;
});

const priceBeforeDiscount = computed(() => {
  return product.value?.price?.data?.original_display_price;
});

const percentage = computed(() => {
  const before = product.value?.price?.data?.original_display_price_in_cents;
  const after = product.value?.price?.data?.display_price_in_cents;

  if (before > 0 && after > 0) {
    return Math.abs(parseInt(((after - before) / before) * 100));
  }
  return 0;
});

onMounted(async () => {
  await getProduct();
});
</script>

<template>
  <div v-if="showPrice">
    <span
        class="tw-block tw-font-bold tw-text-[14px] md:tw-text-[16px] tw-leading-[1.21] tw-text-nl-green-500 md:tw-text-nl-yellow-100">
      {{ pricePrefixText }} {{ format(price) }}/item
    </span>
    <span class="tw-block tw-text-[14px] md:tw-text-[16px] tw-leading-[1.21] tw-font-medium"
          v-if="product?.discount > 0 && product?.discount_type != 'amount'">
      was
      <span
          class="tw-relative after:tw-content-[''] after:tw-absolute after:tw-top-1/2 after:tw-transform after:-tw-translate-y-1/2 after:-tw-left-0.5 after:-tw-right-0.5 after:tw-h-0.5 after:tw-rounded-[2px] after:tw-bg-nl-green-100 after:md:tw-bg-nl-yellow-100">
        {{ format(priceBeforeDiscount) }}
      </span>
      <span v-if="percentage"> ({{ percentage }}% off)</span>
    </span>
  </div>
</template>
