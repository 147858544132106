<script setup lang="ts">
import { ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';

const testimonials = ref([
  {
    'color': 'rgb(112, 207, 244)',
    'image_url': 'https://content.nurturelife.com/wp-content/uploads/2024/06/avatar-01.webp',
    'author_name': 'Lindsey F.',
    'text': 'This is absolutely the best baby/toddler meal service! The food is always fresh, delicious (I even eat them sometimes haha) and good for my baby! She devours them each time no matter the meal.',
  },
  {
    'color': 'rgb(245, 130, 31)',
    'image_url': 'https://content.nurturelife.com/wp-content/uploads/2024/06/avatar-02.webp',
    'author_name': 'Roxy P.',
    'text': 'As a working mom this service has been a dream come true! My son is now eating healthy balanced meals and I don\'t have to cook or clean!!! Wins all around!',
  },
  {
    'color': 'rgb(0, 146, 89)',
    'image_url': 'https://content.nurturelife.com/wp-content/uploads/2024/06/avatar-03.webp',
    'author_name': 'Marissa M.',
    'text': 'This was the second meal service we tried for my toddler and it really beat the competition! Our son loves everything he’s tried and the food is fresh and tastes great.',
  },
]);
</script>

<template>
  <div class="slider-holder tw-relative tw-px-2 md:tw-px-0 tw-h-full">
    <div class="blockquote-slider md:tw-overflow-hidden tw-h-full">
      <Carousel
        class="tw-h-full"
        :items-to-show="1"
        :wrap-around="true"
        :mouse-drag="true"
        :space="20"
        :breakpoints="{
        768: {
          itemsToShow: 3,
          itemsToScroll: 1,
          snapAlign: 'center',
          wrapAround: false
        },
      }"
      >
        <Slide
          v-for="(testimonial, testimonialIdx) in testimonials"
          :key="'testimonial' + testimonialIdx"
          class="tw-flex tw-items-stretch"
        >
          <div
            class="tw-font-bold tw-text-[18px] tw-leading-[1.3889] tw-text-nl-green-100 tw-text-center tw-border-[11px] tw-rounded-[30px] tw-h-full tw-pt-[14px] tw-pb-3 tw-px-3 tw-flex tw-flex-col tw-justify-center tw-bg-nl-yellow-100 md:tw-mx-1"
            :style="'border-color: ' + testimonial.color"
          >
            <div class="tw-w-[72px] tw-h-[72px] tw-mx-auto tw-overflow-hidden tw-rounded-full tw-mb-2.5">
              <img class="tw-object-cover tw-w-full tw-h-full"
                   :src="testimonial.image_url"
                   :alt="testimonial.author_name + ' Avatar'"
              >
            </div>
            <p class="tw-max-w-[230px] md:tw-max-w-[227px] tw-mx-auto tw-mb-2.5">
              {{ testimonial.text }}
            </p>
            <div class="tw-font-medium tw-capitalize tw-text-base">– {{ testimonial.author_name }}</div>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style>
.slider-holder {
  height: 100%;
}

.blockquote-slider .carousel__prev {
  left: -30px;
}

.blockquote-slider .carousel__next {
  right: -30px;
}
</style>
